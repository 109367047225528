import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Menu = _resolveComponent("Menu");

  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_Menu, null, null, 512), [[_vShow, $options.isShowMenu]]), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.name
      })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.name
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  })], 64);
}