import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import FormDialog from "@/components/dialog/FormDialog";
import { loadDict } from "@/util/request";
export default {
  emits: ["onAddSubmit", "onModifySubmit"],
  components: {
    FormDialog: FormDialog
  },
  props: {
    fields: Array //字段配置

  },
  data: function data() {
    return {
      formDialogTitle: "",
      formData: {},
      formType: "",
      dictCache: {} //字典缓存

    };
  },
  methods: {
    showAdd: function showAdd(formData) {
      this.formType = "ADD";
      this.formData = formData || {};
      this.$refs.formDialog.show("新增");
    },
    showModify: function showModify(formData) {
      this.formType = "MODIFY";
      this.formData = formData;
      this.$refs.formDialog.show("修改");
    },
    close: function close() {
      this.$refs.formDialog.close();
    },
    onSubmit: function onSubmit() {
      if (this.formType == "ADD") {
        this.$emit("onAddSubmit", this.formData);
      }

      if (this.formType == "MODIFY") {
        this.$emit("onModifySubmit", this.formData);
      }
    },
    cacheDict: function cacheDict(field) {
      var _this = this;

      //缓存字典
      this.dictCache[field.dictName] = {
        status: 0,
        //字典加载中
        dictItems: [] //字典项

      };
      loadDict(field.dictName).then(function (dictItems) {
        _this.dictCache[field.dictName].dictItems = dictItems;
        _this.dictCache[field.dictName].status = 1; //已加载
      });
    },
    onChange: function onChange(parentFuncion, value) {
      if (parentFuncion) {
        parentFuncion(value);
      }
    },
    setFormValue: function setFormValue(key, value) {
      this.formData[key] = value;
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.fields.forEach(function (field) {
      if (field.dictName) {
        _this2.cacheDict(field);
      }
    });
  }
};