import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import { doGet } from "@/util/request";
export default {
  name: "Menu",
  data: function data() {
    return {
      menus: [],
      userInfo: {}
    };
  },
  methods: {
    handleSelect: function handleSelect(key, keyPath) {// console.log(key)
      // console.log(keyPath)
      // console.log(this.$refs.menu.re);
    },
    loadUserInfo: function loadUserInfo() {
      var _this = this;

      //加载用户信息
      doGet("api/system/session/session").then(function (data) {
        if (data && data.data && data.data.data) {
          var sessionBean = data.data.data[0];
          sessionBean.menus.forEach(function (element) {
            _this.menus.push(element);
          }); // this.menus = sessionBean.menus;

          _this.userInfo = sessionBean.userInfo;

          _this.$forceUpdate();
        }
      });
    },
    logout: function logout() {
      var _this2 = this;

      //加载用户信息
      doGet("api/system/session/logout").then(function (data) {
        //清除缓存
        sessionStorage.clear(); //清除所有cookie

        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);

        if (keys) {
          for (var i = keys.length; i--;) {
            document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
          }
        } //跳转登录


        _this2.$router.push({
          path: "/login"
        });
      });
    }
  },
  mounted: function mounted() {
    this.loadUserInfo();
  }
};