import { doPost } from "@/util/request";
import { ElMessage } from "element-plus";
export default {
  name: "Login",
  data: function data() {
    return {
      isDialogShow: false,
      loginFormData: {
        loginId: "",
        roleName: "",
        password: ""
      }
    };
  },
  methods: {
    onLoginSubmit: function onLoginSubmit() {
      if (!this.loginFormData.loginId) {
        return;
      }

      if (!this.loginFormData.password) {
        return;
      }

      doPost("api/system/session/login", this.loginFormData).then(function (response) {
        if (response && response.code == 200) {
          window.location.href = "/";
        } else {
          ElMessage("登录失败！");
        }
      });
    }
  },
  created: function created() {
    var _this = this;

    document.onkeyup = function (e) {
      if (window.event.keyCode === 13) {
        _this.onLoginSubmit();
      }
    };
  }
};