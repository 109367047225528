import Menu from "@/views/Menu.vue";
export default {
  components: {
    Menu: Menu
  },
  computed: {
    isShowMenu: function isShowMenu() {
      return this.$route.path != "/login" && this.$route.path != "/links";
    }
  }
};