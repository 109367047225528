import { doPost, doPut, doDelete } from "@/util/request";
import SimpleFormDialog from "@/components/dialog/SimpleFormDialog";
export default {
  components: {
    SimpleFormDialog: SimpleFormDialog
  },
  data: function data() {
    return {
      todayList: [],
      fields: [{
        fieldName: "plannedWorkHours",
        fieldLabel: "计划工时(时)",
        isAddField: true
      }, {
        fieldName: "actualWorkHours",
        fieldLabel: "实际工时(时)",
        isAddField: true
      }, {
        fieldName: "plannedStartTime",
        fieldLabel: "计划开始时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "plannedEndTime",
        fieldLabel: "计划结束时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "actualStartTime",
        fieldLabel: "实际开始时间",
        isAddField: true,
        type: "datetime"
      }, {
        fieldName: "actualEndTime",
        fieldLabel: "实际结束时间",
        isAddField: true,
        type: "datetime"
      }]
    };
  },
  methods: {
    queryTodayList: function queryTodayList() {
      var _this = this;

      doPost("/api/project/projectWbs/todayList", {}).then(function (response) {
        if (response.code == 200) {
          _this.todayList = [];
        } else {
          _this.todayList = response;
        }
      });
    },
    onMoreWsbClick: function onMoreWsbClick() {
      this.$router.push({
        path: "/projectWbs"
      });
    },
    handleComplete: function handleComplete(item) {
      this.$refs.formDialog.showModify(item);
    },
    doComplete: function doComplete(item) {
      var _this2 = this;

      var param = {
        id: item.id,
        status: 4,
        plannedWorkHours: item.plannedWorkHours,
        actualWorkHours: item.actualWorkHours,
        plannedStartTime: item.plannedStartTime,
        plannedEndTime: item.plannedEndTime,
        actualStartTime: item.actualStartTime,
        actualEndTime: item.actualEndTime
      };
      doPut("/api/project/projectWbs/model", param).then(function (response) {
        _this2.$refs.formDialog.close();

        _this2.queryTodayList();
      });
    }
  },
  mounted: function mounted() {
    this.queryTodayList();
  },
  activated: function activated() {}
};