export default {
  emits: ["onSubmit"],
  data: function data() {
    return {
      isDialogShow: false,
      title: ""
    };
  },
  methods: {
    show: function show(title) {
      this.isDialogShow = true;

      if (title) {
        this.title = title;
      } else {
        this.title = "表单";
      }
    },
    close: function close() {
      this.isDialogShow = false;
    },
    _onSubmit: function _onSubmit() {
      this.$emit("onSubmit");
    }
  }
};